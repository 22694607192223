<template>
  <div
    class="relative rounded-md shadow-lg bg-white overflow-hidden group
      grid grid-rows-[auto_auto_auto_auto_auto_1fr] border border-gray-200"
    :class="{
      'laptop:grid-cols-[25.6em_auto_1fr_auto] laptop:grid-rows-[auto_auto_auto_1fr]': props.landscape
    }"
  >
    <auth-only :roles="[ UserRole.EDITOR, UserRole.MANAGER, UserRole.ADMINISTRATOR ]">
      <NuxtLink
        :to="localePath({
          name: 'news-news-edit',
          params: {
            news: translated.id!!
          }
        })"
        class="absolute left-auto right-4 top-4 bottom-auto hidden group-hover:block
          bg-primary hover:bg-primary-highlighted active:bg-primary-selected text-white
          text-center rounded-full p-2 font-semibold"
      >
        <font-awesome-icon
          icon="pen"
          class="h-[1.2em] w-[1.2em]"
        />
      </NuxtLink>
    </auth-only>

    <NuxtLink
      :to="localePath({
        name: translated.published ? 'news-news' : 'news-news-edit',
        params: {
          news: translated.published ? translated.slug : translated.id
        }
      })"
      class="relative aspect-video h-full w-full"
      :class="{
         'laptop:row-span-4': props.landscape
      }"
    >
      <span
        v-show="!translated.published"
        class="absolute top-2 left-2 rounded-md overflow-hidden shadow-lg border border-gray-200 bg-white py-1 px-2 text-slate-900 font-light"
      >
        {{ t('jc.component.newsListItem.notPublished') }}
      </span>
      <NuxtPicture
        :src="image.src"
        :alt="image.alt || ''"
        :title="image.title"
        sizes="100vw mobile:640px"
        loading="lazy"
      />
    </NuxtLink>

    <span
      class="bg-primary h-full w-full
        min-h-[5px] min-w-[5px] laptop:min-h-[8px] laptop:min-w-[8px]"
      :class="{
         'laptop:row-span-4': props.landscape
      }"
    />

    <div
      class="mx-8 mt-6 text-slate-900"
      :class="{
         'laptop:col-span-2': props.landscape
      }"
    >
      <div v-html="title" />
    </div>

    <div
      class="flex flex-row gap-2 mx-8 mt-6"
      :class="{
         'laptop:row-start-4 laptop:col-start-3 laptop:mt-auto laptop:mb-6': props.landscape
      }"
    >
      <span
        class="b2 text-slate-700"
      >
        {{ t(`jc.news.type.${translated.type}.title`) }}
      </span>
      <span
        v-if="translated.publishDate"
        class="b2 text-slate-700"
      >
        {{ d(translated.publishDate, 'short') }}
      </span>
    </div>

    <div
      class="mx-8 mt-2 mb-4 text-slate-900"
      :class="{
         'laptop:col-span-2': props.landscape
      }"
    >
      <div v-html="teaser" />
    </div>

    <NuxtLink
      v-show="translated.published"
      :to="localePath({
        name: 'news-news',
        params: {
          news: translated.slug
        }
      })"
      class="bg-primary hover:bg-primary-highlighted active:bg-primary-selected text-white
        text-center rounded py-2 mx-8 mt-auto mb-6 font-semibold"
      :class="{
         'laptop:row-start-4 laptop:col-start-4 laptop:w-[12rem]': props.landscape,
      }"
    >
      {{ t('jc.component.newsListItem.readMoreButton.label') }}
    </NuxtLink>

    <NuxtLink
      v-show="!translated.published"
      :to="localePath({
        name: 'news-news-edit',
        params: {
          news: translated.id
        }
      })"
      class="bg-secondary hover:bg-secondary-highlighted active:bg-secondary-selected text-white
        text-center rounded py-2 mx-8 mt-auto mb-6 font-semibold"
      :class="{
         'laptop:row-start-4 laptop:col-start-4 laptop:w-[12rem]': props.landscape,
      }"
    >
      {{ t('jc.component.newsListItem.editButton.label') }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import {
  type FileReference,
  type News,
  UserRole,
  translate, translateNews, type TranslatedNews,
} from "~/composables/charon";
import { generateTeaser } from "~/composables/charon";
import { generateHTML } from "@tiptap/html";
import { generateText } from "@tiptap/core";
import { teaserExtensions, titleExtensions } from "~/tiptap/config";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const { d, t, locale } = useI18n()
const localePath = useLocalePath()
const { charon } = useCharon()

const props = defineProps<{
  news: News
  // tries to use landscape mode on laptop or higher
  landscape?: boolean
}>()

const translated = computed<TranslatedNews>(() => {
  const translated = translateNews(props.news, locale.value)
  if (translated == null) {
    showError({
      statusCode: 404,
      message: "",
    })
  }
  return translated
})

const { data: fileReference } = useAsyncData(
  `file-reference-${translated.value.image}`,
  () => translated.value.image ? charon<FileReference>(`/file-references/${translated.value.image}`) : Promise.resolve(null),
  {
    watch: [ () => translated.value.image ]
  }
)

const image = computed(() => {
  const trans = translate(fileReference.value?.locales, locale.value)
  return {
    src: translated.value.image ? `/charon/${translated.value.image}` : "images/component/charonFile/news_placeholder.png",
    alt: trans?.description ?? "",
    title: trans?.description,
  }
})

const title = computed(() => {
  const title = translated.value.title
  if (!title) {
    return undefined
  }

  const hasTitle = title.type === 'doc'
  if (!hasTitle) {
    return undefined
  }

  // tries to change the heading level from a h1 to a h3
  if (title.content?.[0]?.attrs?.level) {
    title.content[0].attrs.level = 3
  }
  return generateHTML(title, titleExtensions({ customHeadingLevel: 3 }))
})

const teaser = computed(() => {
  // try to use provided teaser
  const hasTeaser = translated.value.teaser && generateText(translated.value.teaser, teaserExtensions()).length > 0
  if (hasTeaser) {
    return generateHTML(translated.value.teaser!, teaserExtensions())
  }

  // try to use body
  const hasBody = translated.value.body
  if (hasBody) {
    const html = generateHTML(generateTeaser(translated.value.body!)!, teaserExtensions())
    if (html) {
      return html
    }
  }
})
</script>

<style scoped lang="postcss">
:deep(.h1) {
  @apply text-inherit
}

:deep(img) {
  @apply w-full h-full object-cover
}

#title > :deep(*) {
  @apply line-clamp-2
}
#teaser :deep(*) {
  @apply line-clamp-4
}
</style>
